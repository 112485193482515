/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        if (document.getElementById("menu-trigger") !== null) {
          document.getElementById("menu-trigger").onclick = function() {
            if (document.body.classList.contains('open-menu')) {
              // document.getElementById("header").classList.remove('active');
              document.getElementById("menu-trigger").classList.remove('active');
              document.getElementById("nav-primary").classList.remove('active');
              document.body.classList.remove('open-menu');
            } else {
              // document.getElementById("header").classList.add('active');
              document.getElementById("menu-trigger").classList.add('active');
              document.getElementById("nav-primary").classList.add('active');
              document.body.classList.add('open-menu');
            }
            return false;
          };
        }

        var current = 1,
          total_slides = '';

        $('#slider .slider-nav .next').click(function() {
          total_slides = $('#slider .slider-stage').find("[data-slide='" + current + "']").data('slide-total');
          $('.slider-stage .slider-item').removeClass('active');
          if (current === total_slides) {
            current = 1;
          } else {
            current = current+1;
          }
          $('#slider .slider-stage').find("[data-slide='" + current + "']").addClass('active');

          return false;
        });

        $('#slider .slider-nav .prev').click(function() {
          total_slides = $('#slider .slider-stage').find("[data-slide='" + current + "']").data('slide-total');
          $('.slider-stage .slider-item').removeClass('active');
          if (current === 1) {
            current = total_slides;
          } else {
            current = current-1;
          }
          $('#slider .slider-stage').find("[data-slide='" + current + "']").addClass('active');

          return false;
        });

        // JavaScript to be fired on all pages
        $('.block.slider').owlCarousel({
          loop:true,
          margin:0,
          nav:true,
          dots:true,
          items:1,
          thumbs:true,
          thumbsPrerendered: true
        });

        $('.block .slider').owlCarousel({
          loop:true,
          margin:0,
          nav:true,
          dots:false,
          items:1,
          thumbs:true,
          thumbsPrerendered: true
        });

        /*
        $('#header .menu-item-has-children').click(function(){
          if ($(this).hasClass('active')) {
            $(this).removeClass('active');
          } else {
            $(this).addClass('active');
            return false;
          }
        });
        */

        $('.block.gallery a.gallery-popup').click(function() {
          $('#gallery-overlay .gallery-item').removeClass('active');
          $('#gallery-overlay').addClass('active');
          current = $(this).data('slide');
          total_slides = $(this).data('slide-total');
          $('#gallery-overlay').find("[data-slide='" + current + "']").addClass('active');
        });

        $('#gallery-close').click(function() {
          $('#gallery-overlay, #gallery-overlay .gallery-item').removeClass('active');
        });

        $('#gallery-overlay .gallery-next').click(function() {
          $('#gallery-overlay .gallery-item').removeClass('active');

          if (current === total_slides) {
            current = 0;
          } else {
            current = current+1;
          }
          $('#gallery-overlay').find("[data-slide='" + current + "']").addClass('active');
        });

        $('#gallery-overlay .gallery-prev').click(function() {
          $('#gallery-overlay .gallery-item').removeClass('active');
          if (current === 0) {
            current = total_slides;
          } else {
            current = current-1;
          }
          $('#gallery-overlay').find("[data-slide='" + current + "']").addClass('active');
        });

        var header = document.getElementById('header');
        header.addEventListener("mouseover", function(){
          //if (!header.addlassList.contains('active')) {
          //   header.classList.add('show');
          //}
        }, false);

        var lastScrollTop = 80;
        // element should be replaced with the actual target element on which you have applied scroll, use window in case of no target element.
        window.addEventListener("scroll", function(){
          var st = window.pageYOffset || document.documentElement.scrollTop;
          var header = document.getElementById('header');
          if (!header.classList.contains('active')) {
            if (st > lastScrollTop && st > 80) {
              // downscroll code
              // header.classList.add('offtop');
              // header.classList.remove('show');
            } else if (st === 0) {
              // header.classList.remove('offtop');
              // header.classList.remove('show');
            } else {
              // upscroll code
              // header.classList.add('show');
            }
          }
          lastScrollTop = st;
        }, false);

        // Gallery
        function galleryOpen(slide) {
          var wzgOverlay = document.getElementById('gallery-overlay');
          wzgOverlay.classList.add('active');
        }
        function galleryClose() {
          var wzgOverlay = document.getElementById('gallery-overlay');
          wzgOverlay.classList.remove('active');
        }

        window.onkeydown = function(event) {
          if (event.keyCode === 27) {
            var wzgOverlay = document.getElementById('gallery-overlay');
            wzgOverlay.classList.remove('active');
          }
        };
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
